<template>
	<div class="search-page">
		<div class="logo-container">
			<img src="../assets/images/design/logo.svg" alt="Logo" class="logo">
		</div>
		<div class="search-container">
			<input type="text" v-model="searchQuery" @keyup.enter="search" placeholder="Search...">
			<button @click="search">Search</button>
		</div>
		<div class="bookmarks-container">
			<div class="bookmark" v-for="bookmark in bookmarks" :key="bookmark.id">
				<a class="bookmark" :href="bookmark.link" target="_blank">
					<img :src="returnBookmarkIcon(bookmark)" :alt="bookmark.name" class="bookmark-icon" />
					<span class="bookmark-name">{{ bookmark.name }}</span>
				</a>
			</div>
		</div>
    <div class="mt-5" v-if="video">
    <h3> Sponsored Ad</h3>
    <video
        :width="600"
        controls
        loop
        muted
        autoplay
        ref="myvideo"
    >
      <source
          :src="video"  type="video/mp4"
      >
    </video>
	</div>
  </div>
</template>

<script>
export default {
	name: 'SearchPage',
	data() {
		return {
			searchQuery: '',
			bookmarks: [],
      video: false

    }
	},
	created() {
		this.fetchBookmarks()
    this.fetchVideo()
  },
  methods: {
		returnBookmarkIcon(bookmark) {
			if (bookmark.icon) {
				return this.$vars.BACKEND_BASE_URL + '/uploads/bookmarks/' + bookmark.icon
			} else {
				return bookmark.link + '/favicon.ico'
			}
		},
		async fetchBookmarks() {
			const response = await this.$axios.get(`api/computers/search_bookmarks`)
			if (response.status === 200) {
				this.bookmarks = response.data
			}
		},
    async fetchVideo() {
      const response = await this.$axios.get(`api/computers/search_video`)
      if (response.status === 200) {
        this.video = this.$vars.BACKEND_BASE_URL + '/' + response.data.video
      }
    },
		search() {
      const query = encodeURIComponent(this.searchQuery)
			// Redirect to the search page with the query on other tab
			window.open(`https://www.google.com/search?q=${query}`, '_blank')
		},
	},
}
</script>

<style scoped lang="scss">
.search-page {
	max-width: 700px;
	margin: 0 auto;
	padding: 1em;
	padding-top: 7em;
}

.search-container {
	display: flex;
	gap: 1em;
	width: 100%;

	input {
		width: 80%;
		padding: 10px;
		border-radius: 20px;
		border: 1px solid #ccc;
	}

	button {
		height: fit-content;
		width: 20%;
		min-width: 40px;
		padding: 10px 20px;
		border-radius: 20px;
		border: none;
		background-color: #000;
		color: #fff;
		cursor: pointer;
		font-size: 18px;
		font-weight: 600;
	}
}

.logo-container img {
	max-width: 400px;
	margin-bottom: 2em;
	display: block;
	margin-left: auto;
	margin-right: auto;
	border-radius: 4rem;
}

.bookmarks-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1em;
	margin-top: 2em;
}

.bookmark {
	flex: 0 1 calc(20% - 1em);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bookmark-icon {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-bottom: 0.5em;
}

.bookmark-name {
	font-size: 0.75em;
	text-align: center;
	display: block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #000;
}

@media (max-width: 768px) {
	.bookmark {
		flex: 0 1 calc(33.333% - 1em);
	}
}

@media (max-width: 480px) {
	.bookmark {
		flex: 0 1 calc(50% - 1em);
	}
}
</style>
